import { FC, useContext, useEffect } from "react";
import { FaExpand } from "react-icons/fa6";
import PageHeader from "../../../components/commons/page-header";
import PageMainHeader from "../../../components/commons/page-main-header";
import useArrivalsView from "./useArrivalsView";
import useOrderView from "./useOrderView";
import DocumentPreViewer from "../components/document-viewer";
import { deviceInfoContext } from "../../../context";
import edit from "../../../assets/images/edit_round.svg";
import cancel from "../../../assets/images/cancel.svg";
import prev from "../../../assets/images/prev.svg";
import next from "../../../assets/images/next.svg";
import { useState } from "react";
import Modal from "../../../components/ui/modal";
import ModalHeader from "../../../components/ui/modal/modal-header";
import { Button } from "../../../components/ui";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { updatePaymentUpdateStatus } from "../../../reducers/tradeSlice";
import { toast } from "react-toastify";
import useAxiosClient from "../../../hooks/useAxiosClient";
import UserDetails from "../../../components/user-details";
import { getCurrencyNumberWithSymbol } from "../../../helpers/get-currency-number";
import moment from "moment";
import { FaDotCircle } from "react-icons/fa";
import classes from "../style.module.scss";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import PaymentRecDocs from "./paymentRecDocs";
import download from "../../../assets/images/download_icon.svg";
const PaymentHistory: FC<{ onModalClose?: any }> = ({ onModalClose }) => {
  const { width, deviceType } = useContext(deviceInfoContext);
  const params = useParams();
  const { onClickPaymentId } = useOrderView();
  const { alltradeDetail } = useArrivalsView("PAYMENT", false);
  const { handlePutCall } = useAxiosClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [index, setActiveStep] = useState(0);
  const [showCancelPayModal, setShowCancelPayModal] = useState(false);
  // Function to go to the next picture

  useEffect(() => {
    onClickPaymentId();
  }, []);
  const goToNextPicture = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const goToPrevPicture = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { tradeType, paymentHistoryObj, traderId, tradeDetail } = useSelector(
    (state: TRootState) => ({
      tradeType: state.auth.tradeType,
      traderId: state.auth.traderId,
      paymentHistoryObj: state.trades.paymentHistoryObj,
      tradeDetail: state.trades.tradeDetails,
    })
  );
  const updatePayment = async () => {
    try {
      let postBody = {
        action: "CANCEL",
        amount: alltradeDetail?.payment,
        id: paymentHistoryObj?.id,
        images: [],
        pmtMode: "CASH",
        status: 0,
        tradeId: alltradeDetail?.tradeId,
        pmtType: paymentHistoryObj?.pmtType.toUpperCase(),
        traderId: traderId,
        pmtStatus: "CANCEL",
      };

      await handlePutCall<any>({
        URL: "mandi/trade-portal/trades/payments",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          // navigate(`/orders`);
          navigate(`/order-view/${alltradeDetail?.tradeId}`);
          window.location.reload();
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {}
  };
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    const generatePreview = async () => {
      const blob = await pdf(
        <PaymentRecDocs
          details={paymentHistoryObj}
          type={""}
          status={"single"}
          seqId={tradeDetail?.tradeId}
        />
      ).toBlob();
      if (!blob) {
        console.error("Failed to generate PDF Blob");
        return;
      }

      const url = URL.createObjectURL(blob);
      console.log("Generated Blob URL:", url); // Debugging log
      setPreviewUrl(url);
    };

    generatePreview();

    return () => {
      if (previewUrl) URL.revokeObjectURL(previewUrl);
    };
  }, [paymentHistoryObj]);
  return (
    <>
      {/* <PageMainHeader />
      <PageHeader
        showBackButton={true}
        showTitle={true}
        fromScreen={`PaymentHistory`}
        topVal="3.7rem"
        title={` ${
          paymentHistoryObj != null
            ? paymentHistoryObj?.pmtType + ": " + paymentHistoryObj?.id
            : ""
        }`}
        subText={alltradeDetail?.tradeStatus}
      /> */}
      {paymentHistoryObj != null && (
        <div className="py-4 px-4">
          <div className="bg-white border br-10 rounded-t-lg mb-3">
            <div className="px-3">
              <div className="flex py-3 border-b">
                {tradeType === "SOURCE" && (
                  <div className="flex items-center">
                    <UserDetails
                      profilePic={""}
                      name={"From : " + tradeDetail?.destTradeName}
                      partnerMobile={tradeDetail?.destMobile}
                      partnerId={tradeDetail?.destPartyId}
                      seqId={tradeDetail?.traderSeq}
                      partyName={tradeDetail?.destName}
                    />
                  </div>
                )}
                {tradeType === "DEST" && (
                  <div className="flex items-center">
                    <UserDetails
                      profilePic={""}
                      name={"To : " + tradeDetail?.srcTradeName}
                      partnerMobile={tradeDetail?.srcMobile}
                      partnerId={tradeDetail?.srcPartyId}
                      seqId={tradeDetail?.traderSeq}
                      partyName={tradeDetail?.srcName}
                    />
                  </div>
                )}
              </div>
              <div className="flex py-3 border-b justify-between">
                <span className="flex flex-col">
                  <span className="color-sub-text text-xs">
                    Payment Status{" "}
                  </span>
                  {paymentHistoryObj?.pmtStatus ? (
                    <span
                      className={`${classes.orderStatus} font-medium text-sm flex items-center`}
                      data-status={paymentHistoryObj?.pmtStatus}
                    >
                      {paymentHistoryObj?.pmtStatus
                        ? paymentHistoryObj?.pmtStatus == "CANCELLED"
                          ? "REJECTED"
                          : paymentHistoryObj?.pmtStatus
                        : "-"}
                    </span>
                  ) : (
                    "-"
                  )}
                </span>
                <span className="flex flex-col">
                  <span className="color-sub-text text-xs">
                    {/* Reconciliation Comments */}
                    Cancelled Reason
                  </span>
                  <span className="font-medium text-sm">
                    {paymentHistoryObj?.rejectedReason || "-"}
                  </span>
                </span>
              </div>
              <div className="flex py-3 border-b justify-between">
                <span className="flex flex-col">
                  <span className="color-sub-text text-xs">Payment Date</span>
                  <span className="font-medium text-sm">
                    {moment(paymentHistoryObj?.date).format("DD-MMM-YYYY") ||
                      "-"}
                  </span>
                </span>
                <span className="flex flex-col">
                  <span className="color-sub-text text-xs">Amount Paid</span>
                  <span className={"font-medium text-sm"}>
                    {getCurrencyNumberWithSymbol(paymentHistoryObj?.amount) ||
                      "-"}
                  </span>
                </span>
              </div>
              <div className="flex py-3 border-b justify-between">
                <span className="flex flex-col">
                  <span className="color-sub-text text-xs">Payment Type</span>
                  <span className="font-medium text-sm">
                    {paymentHistoryObj?.pmtType || "-"}
                  </span>
                </span>
                <span className="flex flex-col">
                  <span className="color-sub-text text-xs">Mode</span>
                  <span className={"font-medium text-sm"}>
                    {paymentHistoryObj?.pmtMode || "-"}
                  </span>
                </span>
              </div>
              <div className="flex py-3 border-b justify-between">
                <span className="flex flex-col">
                  <span className="color-sub-text text-xs">Comments</span>
                  <span className="font-medium text-sm">
                    {paymentHistoryObj?.comments || "-"}
                  </span>
                </span>
              </div>
            </div>
          </div>
          {paymentHistoryObj?.images != null &&
            paymentHistoryObj?.images.length > 0 && (
              <>
                <span className="color-sub-text text-xs">Payment Receipt</span>
                <div className="flex flex-row overflow-auto hide-scroll-bar">
                  {paymentHistoryObj.images.map((item, i) => (
                    <div
                      key={`tapal-invoice-${i}`}
                      className="w-1/2 shrink-0 px-2 py-1"
                    >
                      <div className="border-2 p-2 rounded flex justify-center relative min-h-full">
                        {/* {paymentHistoryObj.images[i].url.includes(".pdf") ? (
                          {/* <iframe
                            src={item.url}
                            className="h-full w-full"
                            style={{ pointerEvents: "none" }}
                          ></iframe>
                        ) : (
                          <img src={item.url} />
                        )} */}
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <DocumentPreViewer
                            url={item.url}
                            docType={
                              item.url.includes(".pdf") ? "pdf" : "image"
                            }
                          />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          {tradeType == "SOURCE" &&
            paymentHistoryObj?.refId &&
            paymentHistoryObj?.pmtStatus == "ACCEPTED" && (
              <>
                <span className="color-sub-text text-xs">Payment Receipt</span>
                <PDFDownloadLink
                  document={
                    <PaymentRecDocs
                      details={paymentHistoryObj}
                      type={""}
                      status={"single"}
                      seqId={tradeDetail?.tradeId}
                    />
                  }
                  fileName="paymentreceipt.pdf"
                >
                  <div className="flex gap-2 items-center">
                    {previewUrl ? (
                      <div className="relative">
                        {" "}
                        <iframe
                          src={previewUrl}
                          width="200"
                          height="200"
                          className="border relative"
                        />
                        <a
                          href={previewUrl}
                          download="paymentreceipt.pdf"
                          target="_blank"
                        >
                          <img
                            src={download}
                            className="absolute top-3 right-3"
                          />
                        </a>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </PDFDownloadLink>
              </>
            )}
          {tradeType == "DEST" && paymentHistoryObj?.pmtStatus == "PENDING" && (
            <div
              className="bg-white flex justify-between p-4 fixed bottom-0 left-0 w-full border-t"
              style={{ width: width }}
              data-element="order-view-footer"
            >
              <span
                className="flex flex-col items-center cursor-pointer"
                onClick={() => {
                  setShowCancelPayModal(true);
                }}
              >
                <img src={cancel} alt="image" className="w-8 h-8" />
                <span className="text-xs">Cancel</span>
              </span>
              <span
                className="flex flex-col items-center cursor-pointer"
                onClick={() => {
                  navigate(`/order-view/${paymentHistoryObj.tradeId}`);
                  localStorage.setItem("activeTab", "paymentDetails");
                  dispatch(
                    updatePaymentUpdateStatus({ paymentUpdateStatus: true })
                  );
                  onModalClose(false);
                }}
              >
                <img src={edit} alt="image" className="w-8 h-8" />
                <span className="text-xs">Edit</span>
              </span>
            </div>
          )}

          {showCancelPayModal === true && (
            <>
              <Modal
                open={showCancelPayModal}
                width={deviceType === "MOBILE" ? "80vw" : "400px"}
                onClose={() => {
                  setShowCancelPayModal(false);
                }}
                modalWidth={deviceType === "MOBILE" ? "100vw" : width}
              >
                <>
                  <ModalHeader title={"Cancel Payment"} content={<></>} />
                  <div className="text-center py-3 px-6">
                    <img
                      src={cancel}
                      className="h-8 w-8 flex justify-center items-center mx-auto mb-2"
                    />
                    <span className="text-sm">
                      You are about to cancel the payment. Are you sure you want
                      to proceed?
                    </span>
                  </div>
                  <div className="flex w-full items-center bottom-0 left-0 right-0 px-4 py-4 border-top gap-2">
                    <Button
                      variant="primaryLight"
                      className="w-full"
                      text="NO"
                      onClick={() => {
                        setShowCancelPayModal(false);
                      }}
                    />
                    <Button
                      variant="contained"
                      className="w-full"
                      onClick={() => {
                        updatePayment();
                      }}
                    >
                      YES
                    </Button>
                  </div>
                </>
              </Modal>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default PaymentHistory;
