import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "../../hooks/useAxiosClient";
import {
  updateAllReports,
  updateAllTrades,
  updateCustomReports,
  updateProgressDetails,
} from "../../reducers/tradeSlice";
import { TRootState } from "../../store";
import { TTradeDetails } from "../../types";
const useReports = () => {
  const {
    tradeType,
    traderId,
    allReports,
    tradeDetails,
    allCustomReports,
    subValidityRes,
  } = useSelector((state: TRootState) => ({
    tradeType: state.auth.tradeType,
    traderId: state.auth.traderId,
    allReports: state.trades.allReports,
    tradeDetails: state.trades.tradeDetails,
    allCustomReports: state.trades.allCustomReports,
    subValidityRes: state.auth.subValidityRes,
  }));
  const dispatch = useDispatch();

  const { handleGetCall } = useAxiosClient();
  const [reportDetails, setReportDetails] = useState<TTradeDetails[]>([]);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [dateRange, setDateRange] = useState({
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });
  //   const [activeTab, setActiveTab] = useState<"open" | "close">("open");
  const onChangeDateRange = (data: any) => {
    if (
      (data.fromDate !== dateRange.fromDate &&
        data.toDate !== dateRange.toDate) ||
      true
    ) {
      setDateRange({ fromDate: data.fromDate, toDate: data.toDate });
    }
  };
  const [searchText, setSearchText] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  //   useEffect(() => {
  //     setDateRange({ fromDate: "", toDate: "" });
  //   }, []);
  useEffect(() => {
    if (activeTab === "all") {
      handleGetCall<TTradeDetails[]>(
        `mandi/trade-portal/trades/traderId/${traderId}`
      ).then(({ data }) => {
        if (data) {
          setReportDetails(data);
          dispatch(updateAllReports({ allReports: data }));

          setRefreshStatus(false);
        } else {
          setReportDetails([]);
        }
      });
    }
    if (activeTab === "custom") {
      handleGetCall<TTradeDetails[]>(
        `mandi/trade-portal/trades/traderId/${traderId}?fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}`
      ).then(({ data }) => {
        if (data) {
          setReportDetails(data);
          dispatch(updateCustomReports({ allCustomReports: data }));
          setRefreshStatus(false);
        } else {
          setReportDetails([]);
        }
      });
    }
  }, [
    dateRange.fromDate,
    dateRange.toDate,
    refreshStatus && searchText == "",
    activeTab,
  ]);

  const handelSearch = (e: any) => {
    let searchtext = e.target.value;
    setSearchText(searchtext);
    const reports = activeTab == "all" ? allReports : allCustomReports;
    if (searchText) {
      let searchTextLower = searchtext.toLowerCase();
      let filteredBuyers = reports.filter((partner) => {
        if (
          (partner.destTradeName &&
            partner.destTradeName.toLowerCase().includes(searchTextLower)) ||
          (partner.srcTradeName &&
            partner.srcTradeName.toLowerCase().includes(searchTextLower)) ||
          (partner.tradeMode &&
            partner.tradeMode.toLowerCase().includes(searchText)) ||
          //   (partner.tradeId &&
          //     partner.tradeId.toString().includes(searchTextLower)) ||
          (partner.tradeStatus &&
            partner.tradeStatus.toLowerCase().includes(searchTextLower)) ||
          (partner.tradeId &&
            partner.tradeId.toString().includes(searchTextLower)) ||
          (partner.srcVehNum != null &&
            partner.srcVehNum.toLowerCase().includes(searchTextLower)) ||
          (partner.destVehNum != null &&
            partner.destVehNum.toLowerCase().includes(searchTextLower))
        ) {
          return true;
        } else {
          return false;
        }
      });

      setReportDetails(filteredBuyers);
      setRefreshStatus(false);
    } else {
      setRefreshStatus(true);
      if (activeTab == "all") {
        setReportDetails(allReports);
      } else {
        setReportDetails(allCustomReports);
      }
    }
  };
  return {
    tradeType,
    onChangeDateRange,
    reportDetails,
    setRefreshStatus,
    activeTab,
    setActiveTab,
    handelSearch,
    searchText,
    setSearchText,
    tradeDetails,
    dateRange,
    subValidityRes,
  };
};
export default useReports;
