import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LOGGEDIN, LOGGEDIN_USER_INFO } from "../../constants/storage-keys";
import useAxiosClient from "../../hooks/useAxiosClient";
import {
  otpValidationData,
  setSiignInData,
  setLoggedinStatus,
} from "../../reducers/authSlice";
import { TRootState } from "../../store";
let otpTimer: any = null;
const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handlePostCall } = useAxiosClient();
  const { regStatus } = useSelector((state: TRootState) => ({
    otpReqId: state.auth.otpReqId,
    traderId: state.auth.traderId,
    regStatus: state.auth.regStatus,
  }));
  const [otpExpTime, setOtpExpTime] = useState(0);
  const [sendOtpResponse, setSendOtpResponse] = useState<{
    otpReqId: string;
    traderId: number;
  }>({ otpReqId: "", traderId: 0 });
  const [sentOtpBtnText, setSendOtpButtonText] = useState("GET OTP");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpNumber, setOtpNumber] = useState<string>("");
  const [fullName, setFullName] = useState("");
  const [tradeName, setTradeName] = useState("");
  const [tradeType, setTradeType] = useState("");
  const [step, setStep] = useState(0);
  const verifyOtp = async () => {
    if (mobileNumber === "") {
      toast.error("Please enter mobile number");
      return;
    }
    if (mobileNumber.length !== 10) {
      toast.error("Mobile number must be 10 digit");
      return;
    }
    if (otpNumber === "") {
      toast.error("Please enter OTP");
      return;
    }
    if (otpNumber.length !== 6) {
      toast.error("Please enter 6 digit OTP");
      return;
    }
    let postBody = {
      mobile: mobileNumber,
      otp: otpNumber.toString(),
      otpReqId: sendOtpResponse.otpReqId,
      traderId: sendOtpResponse.traderId,
    };
    await handlePostCall<any>({
      URL: "account/mandi/portal/login/otp/validation",
      apiParams: postBody,
    })
      .then(({ data, status }) => {
        localStorage.setItem(LOGGEDIN, "1");
        localStorage.setItem(
          LOGGEDIN_USER_INFO,
          JSON.stringify({
            traderId: data.traderId,
            regStatus: data.regStatus,
            name: data.name,
            tradeName: data.tradeName,
            tradeType: data.tradeType,
            mobile: data.mobile,
            clientId: data.clientId,
            clientSecret: data.clientSecret,
          })
        );
        dispatch(
          otpValidationData({
            traderId: data.traderId,
            regStatus: data.regStatus,
            name: data.name,
            tradeName: data.tradeName,
            tradeType: data.tradeType,
            mobile: mobileNumber,
            // clientId: data.clientId,
            // clientSecret: data.clientSecret,
          })
        );
        if (data.regStatus === "NEW_USER") {
          setStep(1);
        } else {
          navigate("/orders");
          localStorage.setItem(LOGGEDIN, "1");
          dispatch(
            setLoggedinStatus({
              loggedin: true,
            })
          );
          // window.location.reload();
        }
        localStorage.setItem("clientId", data.clientId);
        localStorage.setItem("clientSecret", data.clientSecret);

        toast.success(status.message);
      })
      .catch((err: any) => {
        toast.error(err.response.data.status.message);
      });
  };
  const onCreateAcount = async () => {
    if (fullName === "") {
      toast.error("Please enter Full Name");
      return;
    }
    if (tradeName === "") {
      toast.error("Please enter Trade Name");
      return;
    }
    if (tradeType === "") {
      toast.error("Please enter Trade Type");
      return;
    }
    let postBody = {
      mobile: mobileNumber,
      altMobile: "",
      name: fullName,
      regStatus: regStatus,
      status: "",
      tradeName: tradeName,
      tradeType: tradeType,
      traderId: sendOtpResponse.traderId,
      verified: "",
    };
    await handlePostCall<any>({
      URL: "account/mandi/portal/account",
      apiParams: postBody,
    })
      .then(({ data, status }) => {
        dispatch(
          setLoggedinStatus({
            loggedin: true,
          })
        );
        dispatch(
          otpValidationData({
            traderId: sendOtpResponse.traderId,
            regStatus: regStatus,
            name: fullName,
            tradeName: tradeName,
            tradeType: tradeType,
            mobile: mobileNumber,
          })
        );
        localStorage.setItem(LOGGEDIN, "1");
        localStorage.setItem(
          LOGGEDIN_USER_INFO,
          JSON.stringify({
            traderId: sendOtpResponse.traderId,
            regStatus: regStatus,
            name: fullName,
            tradeName: tradeName,
            tradeType: tradeType,
            mobile: mobileNumber,
          })
        );
        window.location.reload();
        toast.success(status.message);
      })
      .catch((err: any) => {
        toast.error(err.response.data.status.message);
      });
  };
  const runOtpTimer = (timer: number) => {
    if (timer >= 0) {
      setOtpExpTime(timer);
      setTimeout(() => {
        runOtpTimer(timer - 1);
      }, 1000);
    } else {
      setSendOtpButtonText("RESEND OTP");
    }
  };
  const getOtp = async () => {
    if (mobileNumber === "") {
      toast.error("Please enter mobile number");
      return;
    }
    if (mobileNumber.length !== 10) {
      toast.error("Mobile number must be 10 digit");
      return;
    }
    let toastid = toast.loading(`Sending OTP...`, { type: "info" });
    let postBody = {
      mobile: mobileNumber,
    };
    await handlePostCall<any>({
      URL: "account/mandi/portal/login",
      apiParams: postBody,
    })
      .then(({ data, status }) => {
        if (status.code === "200") {
          setSendOtpResponse({
            otpReqId: data.otpReqId,
            traderId: data.traderId,
          });
          toast.update(toastid, {
            type: "success",
            render: status.description,
            isLoading: false,
            autoClose: 3000,
          });
          setOtpExpTime(60);
          if (otpTimer !== null) {
            clearTimeout(otpTimer);
          }
          otpTimer = setTimeout(() => {
            runOtpTimer(59);
          }, 1000);
        }
      })
      .catch((err: any) => {
        toast.update(toastid, {
          render:
            err.response?.data?.status?.description || "Failed to send OTP",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  };

  const onChangeTrade = (val: any) => {
    setTradeType(val);
  };
  return {
    sentOtpBtnText,
    step,
    setStep,
    verifyOtp,
    onCreateAcount,
    setMobileNumber,
    getOtp,
    setOtpNumber,
    regStatus,
    setFullName,
    setTradeName,
    onChangeTrade,
    otpExpTime,
    tradeType,
  };
};
export default useLogin;
