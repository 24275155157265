import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TTraderDocs } from "../../types";
import { TRootState } from "../../store";
import {
  setKycDocsInfo,
  setMosResponse,
  setSrcBusinessAcc,
  setSubValidityResponse,
} from "../../reducers/authSlice";
import axios from "axios";
import { API_BASE_URL } from "../../constants";
const useGetByIdDocs = () => {
  const { handleGetCall } = useAxiosClient();

  const [docs, setDocs] = useState<TTraderDocs | null>();

  const { traderId, mos_authkeys } = useSelector((state: TRootState) => ({
    traderId: state.auth.traderId,
    mos_authkeys: state.auth.mos_authkeys,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetCall<TTraderDocs>(
      `account/mandi/portal/account/getKycDocs/traderId${traderId}`
    ).then(({ data }) => {
      if (data) {
        setDocs(data);
        dispatch(setKycDocsInfo(data));
        dispatch(setSrcBusinessAcc(data.bsnsAccnt[0].id));
        localStorage.setItem("mos_authkeys", JSON.stringify(data?.mosAuthKeys));
        dispatch(setMosResponse(JSON.stringify(data?.mosAuthKeys)));
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${API_BASE_URL}payments/mos-subscription/sub/validity/mosId/${data?.mosAuthKeys.mosId}?writerId=0`,
              {
                headers: {
                  "client-iD": data?.mosAuthKeys.clientId,
                  "client-secret": data?.mosAuthKeys.clientSecret,
                  "Content-Type": "application/json",
                },
              }
            );
            const datanew = response;
            localStorage.setItem(
              "subValidityRes",
              JSON.stringify(datanew.data.data)
            );
            dispatch(setSubValidityResponse(JSON.stringify(datanew.data.data)));
          } catch (error) {
            console.log(error);
          }
        };

        fetchData();
      } else {
        setDocs(null);
      }
    });
  }, []);

  return { docs, setDocs };
};
export default useGetByIdDocs;
