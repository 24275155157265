import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import "../../../fonts"; // Ensure the fonts are registered
import watermark from "../../../assets/images/watermark.png"; // Make sure this path is correct
import ono_logo from "../../../assets/images/ono_logo_pdf.png";
import axis from "../../../assets/images/axis.png";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
  numberToWordsWithDecimals,
} from "../../../helpers/get-currency-number";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    paddingTop: 80, // Adjust to leave space for the header
    paddingBottom: 50, // Adjust to leave space for the footer
    paddingHorizontal: 40,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 11,
    color: "#000000",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "10px solid #16A02C",
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    // marginBottom: 70,
    paddingTop: 20,
    borderBottom: "1px solid black",
    // width: "100%",
  },
  logo: {
    width: 80,
  },
  axis: {
    width: 50,
  },
  header_head: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 50,
    marginTop: 30,
    width: "100%",
    fontWeight: "bold",
  },
  footer: {
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "left",
    paddingLeft: 40,
    paddingRight: 40,
    // borderTop: "1px solid black",
    borderBottom: "5px solid #16A02C",
    paddingTop: 20,
    // color: "grey",
  },
  watermark: {
    position: "absolute",
    top: "30%",
    left: "15%",
    width: 300,
    height: 300,
    // opacity: 0.3, // Make the watermark semi-transparent
    // transform: "rotate(-45deg)",
    zIndex: -1, // Ensure it's behind the content
  },
  section: {
    marginBottom: 20,
    fontSize: 10,
    paddingTop: 20,
    lineHeight: 1.5,
  },

  para: {
    fontSize: 12,
  },

  paragraph: {
    marginBottom: 10,
  },

  footerPara: {
    fontSize: 10,
    color: "#121212",
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid ",
  },
  footerParaSm: {
    fontSize: 10,
    color: "#595959",
  },
  bold: {
    fontWeight: "bold",
  },

  pageno: {
    paddingTop: 10,
    textAlign: "center",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },

  flex: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    // justifyContent: "center",
    width: "100%",
    marginBottom: 5,
  },
  col1: {
    width: "50%",
  },
  col2: {
    width: "50%",
  },

  border: {
    borderTop: "1px dotted black",
    // paddingBottom: 20,
    paddingTop: 10,
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  borderTop: {
    borderTop: "0px",
  },

  flexCol: {
    display: "flex",
    flexDirection: "column",
  },

  blue: {
    color: "#0066ff",
  },
  paymentText: {
    fontSize: 14,
    textAlign: "center",
    width: "100%",
  },
});

// Watermark component to be used on each page
const Watermark = () => (
  <View fixed style={styles.watermark}>
    <Image src={watermark} style={styles.watermark} />
  </View>
);

const SrcPaymentReceiptDoc = ({ details, type, seqId }) => (
  <>
    {/* <Page size="A4" style={styles.page}> */}
    <Watermark />
    {/* Header */}
    <View fixed style={styles.header}>
      <Image src={ono_logo} style={styles.logo} />
      <Image src={axis} style={styles.axis} />
    </View>

    {/* Table */}
    <View style={styles.section}>
      <View>
        <Text style={styles.header_head}>Payment Summary</Text>
      </View>
      <View style={styles.para}>
        <View>
          <Text style={styles.paymentText}>
            Payment of{" "}
            {details?.amount
              ? getCurrencyNumberWithSymbol(details?.amount)
              : ""}{" "}
            to {details?.actHolder}{" "}
          </Text>
        </View>
        <View style={styles.border}></View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>Reference ID :</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>
                {details?.refId || "-"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>From:</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>
                ONO ARK PRIVATE LIMITED
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>To:</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>
                {details?.actHolder}({details?.actNum})
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>Amount:</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>
                {details?.amount
                  ? getCurrencyNumberWithSymbol(details?.amount)
                  : "-"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>Transaction Date:</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>
                {details?.pmtReceiptDate
                  ? moment(details?.pmtReceiptDate).format("DD-MMM-YYYY")
                  : moment(details?.date).format("DD-MMM-YYYY")}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>UTR:</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>
                {details?.utr || "-"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>Payment Mode:</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>{details?.pmtMode}</Text>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>Purpose:</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>
                {details?.pmtType} for the Trade ID: {seqId}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.col1}>
            <View>
              <Text style={styles.right}>Status:</Text>
            </View>
          </View>
          <View style={styles.col2}>
            <View>
              <Text style={[styles.bold, styles.left]}>
                Transaction Successful
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
    {/* Footer with Page Numbers */}

    <View style={styles.footer} fixed>
      <Text style={styles.footerPara}>
        {details?.updatedOn
          ? moment(details?.updatedOn).format("DD-MMM-YYYY | hh:mm:a")
          : "-"}
        {/* Registered Office: No 148, Bhive Workspace, 5th Main Road, Sector 6, HSR
        Layout, Bengaluru, Karnataka, 560102 */}
      </Text>
    </View>
    {/* </Page> */}
  </>
);

export default SrcPaymentReceiptDoc;
